import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { RegularHero } from '../components/RegularHero';
import { Seo } from '../components/Seo';
import { colors, device } from '../components/layout/GlobalStyles';
import styled from '@emotion/styled';

const StyledInsurance = styled.div`
    padding: 1em;
    .white-container {
        padding: 2em 1em;
        background: ${colors.white};
    }
    .image-container {
        div {
            margin: 1em 0em;
        }
    }
    ul {
        padding-left: 1.2em;
    }
    h2 {
        margin: 1em 0em;
    }
    p {
        margin: 1em 0em;
    }
    @media ${device.tablet} {
        .image-container {
            display: flex;
            img {
                width: 500px;
                height: 500px;
            }
            div {
                margin: 4em 1em 0em;
            }
        }
    }
    @media ${device.laptop} {
        max-width: 1200px;
        margin: 0 auto;
        .white-container {
            max-width: 1200px;
            margin: 0 auto;
        }
        .image-container {
            display: flex;
            img {
                width: 600px;
                height: 600px;
            }

            div {
                margin: 4em 1em 0em;
            }
        }
    }
    @media ${device.laptopL} {
        .image-container {
        }
        max-width: 1300px;
    }
`;

const Insurance: Page = () => (
    <>
        <DefaultLayout>
            <Seo
                title="Insurance & Financing, FL | Smilecraft Dental Studio"
                description="Dental Insurance and Financing. Dentists at Smilecraft Dental Studio believes that everyone has a right to quality, affordable oral health care, close to home."
            />
            <RegularHero prevPage="" currentPage="Insurance & Financing">
                <StaticImage
                    quality={100}
                    className="regular-hero-image"
                    src="../images/dentist-instructing.jpg"
                    placeholder="blurred"
                    alt="dentist demonstrating with patient"
                />
            </RegularHero>
            <StyledInsurance>
                <h2>Explore Our Accepted Insurances</h2>
                <p>
                    Our goal at SmileCraft is to provide unparalleled dental care to the families in
                    the greater Lutz community. We accept all insurances to ensure that your smile
                    is covered.
                </p>
                <p>
                    To ensure that you receive all the insurance benefits you’re entitled to, we
                    offer our patients a complimentary benefits check. We will work with your
                    insurance to find all possible savings and maximize your coverage.
                </p>
                <div className="white-container">
                    <StaticImage
                        quality={100}
                        src="../images/lending-club.png"
                        placeholder="none"
                        alt="lending-club logo"
                    />
                    <StaticImage
                        quality={100}
                        src="../images/care-credit.png"
                        placeholder="none"
                        alt="care-credit logo"
                    />
                    <h2>Learn About Our Third-Party Lenders</h2>
                    <p>
                        We want our patients to have peace of mind when it comes to financing our
                        great dental care. We offer a variety of ways to fund your smile by working
                        with the following third-party lenders:
                    </p>
                    <ul>
                        <li>LendingClub</li>
                        <li>CareCredit</li>
                    </ul>
                </div>
                <div className="image-container">
                    <StaticImage
                        quality={100}
                        src="../images/teeth-exam.jpg"
                        placeholder="none"
                        alt="teeth getting examined"
                    />
                    <StaticImage
                        quality={100}
                        src="../images/teeth-exam-two.jpg"
                        placeholder="none"
                        alt="fake teeth getting examined"
                    />
                </div>
                <h2>Nationwide Patient Smile Protection Dental Warranty</h2>
                <p>
                    At SmileCraft, we understand that life may not go as planned. We want to assure
                    you that your dental care is as important to us, as it is to you. Therefore, for
                    your peace-of-mind and continued trust, we offer a dental warranty* on the
                    following treatments:
                </p>
                <ul>
                    <li>Fillings</li>
                    <li>Crowns</li>
                    <li>Dentures</li>
                    <li>Bridges & Partials</li>
                    <li>Night Guards</li>
                    <li>Veneers</li>
                    <li>Dental Implants</li>
                    <li>Root Canals</li>
                    <li>Inlays & Onlays</li>
                </ul>
                <p style={{ fontWeight: 'bold' }}>Warranty Benefits:</p>
                <span style={{ fontWeight: 'bold' }}>Warranty Benefits:</span>
                <ul>
                    <li>
                        <span style={{ fontWeight: 'bold' }}>Full Coverage</span> means you get 100%
                        of the original value of the treatment towards any repair or replacement of
                        the covered procedure.
                    </li>
                    <li>
                        <span style={{ fontWeight: 'bold' }}>Nationwide</span> coverage ensures that
                        even if you move or travel, your warranty is valid. This allows for the
                        opportunity to redeem your coverage with any dentist.
                    </li>
                    <li>
                        <span style={{ fontWeight: 'bold' }}>Life Proof</span> protection for your
                        smile from accidents, popcorn kernels, new decay, and whatever else life
                        throws your way. In order to qualify for your warranty benefits, you must
                        stay current on your regular hygiene visits as prescribed by your dentist.
                        To learn more about protecting your smile and for warranty coverage details,
                        contact our office today!
                    </li>
                </ul>
                <p style={{ fontStyle: 'italic' }}>*This is not insurance.</p>
            </StyledInsurance>
        </DefaultLayout>
    </>
);

export default Insurance;
